import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// 🔥 Вставьте свои данные из Firebase Console
const firebaseConfig = {
  apiKey: "AIzaSyBmiwN0XRZMTGvTvirh3qu2VuYwWiIWIlo",
  authDomain: "ftownpl.firebaseapp.com",
  projectId: "ftownpl",
  storageBucket: "ftownpl.firebasestorage.app",
  messagingSenderId: "1056185880490",
  appId: "1:1056185880490:web:d475fe50bf8621a6b965d3",
  measurementId: "G-BLVFXXYPRG",
};


// Инициализация Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export { messaging, getToken, onMessage };
