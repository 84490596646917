import api from "../../api/api";
import { ROLE } from "../../const";
import { debounceRequest } from "../../helpers/debounceRequest";
import { delay, getActiveColor, getActiveSize } from "../../helpers/helpers";
import Text from "../../helpers/Text";
import { errorAlertIcon, successAlertIcon } from "../../images";
import { textErrorMessage, textSuccessMessage } from "../modalStorage/modalWindow/modalWindow";

export const cart = store => {
    const apiCart = api.cartApi

    store.on('@init', () => ({ numberProductPastInCart: null }))
    store.on('saveNumberProductAddToCart', ({ numberProductPastInCart }, obj, { dispatch }) => ({ numberProductPastInCart: obj.productId }))

    store.on('@init', () => ({ numberCurrentOrderForAddProduct: 0 }));
    store.on('setNumberCurrentOrderForAddProduct', ({ _ }, obj) => ({ numberCurrentOrderForAddProduct: obj }));

    store.on('setNumberOrderForAddProducts', async ({ context, closeModalState, cartStore }, obj, { dispatch }) => {
        try {
            let params = []
            if (obj.numberOrder) {
                params = [{
                    add_product: true,
                    add_order_id: +obj.numberOrder
                }]
                dispatch('setNumberCurrentOrderForAddProduct', +obj.numberOrder)
            } else {
                dispatch('setNumberCurrentOrderForAddProduct', 0)
            }

            const newContext = {
                ...context,
                "init_state": {
                    ...context.init_state,
                    dataCart: {
                        ...context.init_state.dataCart,
                        valueButtonNextToOrder: obj.numberOrder === null ? Text({ text: 'go.to.registration' }) : 'Перейти к добавлению'
                    },
                    numberCurrentOrderForAddProduct: +obj.numberOrder
                }
            }
            //---------------------------------------------------------------
            dispatch('setDataCartStore', {
                ...cartStore,
                valueButtonNextToOrder: obj.numberOrder === null ? Text({ text: 'go.to.registration' }) : 'Перейти к добавлению',
                isLoadingCart: true
            })
            //---------------------------------------------------------------
            dispatch('context', newContext)
            await delay(100)
            dispatch('updateInProductCard', params)
            return { numberCurrentOrderForAddProduct: obj.numberOrder }
        } catch (err) {
            console.log('ERROR setNumberOrderForAddProducts func(setNumberOrderForAddProducts) ', err);
            let error = [Text({ text: 'error-on-server' }), 'ERROR setNumberOrderForAddProducts func(setNumberOrderForAddProducts) '];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('getDataCart', async ({ context, closeModalState, cartStore }, obj, { dispatch }) => {

        try {
            dispatch('setDataCartStore', {
                ...cartStore,
                valueButtonNextToOrder: Text({ text: 'go.to.registration' }),
                isLoadingCart: obj?.isLoadingCart ?? false
            })
            const res = await apiCart.getCartData();

            let tempElement = true;
            let amountTrueItem = 0;
            let allCount = 0;
            let valueEnableAllSelectFromServer = false;
            if (res.cartitem_set[0]?.items) {

                await res.cartitem_set.reduce((prev, cur, index, arr) => {
                    cur.items.filter(el => {
                        allCount += cur.items.length;
                        if (tempElement === el.selected) amountTrueItem++
                        if (allCount === amountTrueItem) return valueEnableAllSelectFromServer = true;
                        return valueEnableAllSelectFromServer = false
                    })
                }, 0)
            } else {
                await res.cartitem_set.reduce((prev, cur, index, arr) => {
                    const allCount = arr.length;
                    if (tempElement === cur.selected) amountTrueItem++
                    if (allCount === amountTrueItem) return valueEnableAllSelectFromServer = true;
                    return valueEnableAllSelectFromServer = false
                }, 0)
            }

            const newContext = {
                ...context,
                "init_state": {
                    ...context.init_state,
                    profile: {
                        ...context.init_state.profile,
                        cart: res.in_cart
                    },
                },
            };
            //---------------------------------------------------------------
            dispatch('setDataCartStore', {
                ...cartStore,
                cartitem_set: [...res.cartitem_set],
                in_stock: [...res.in_stock],
                ...res,
                enableAllSelect: valueEnableAllSelectFromServer,
                valueButtonNextToOrder: Text({ text: 'go.to.registration' }),
                agreeWitheRegulations: true,
                isLoadingCart: true
            })
            //---------------------------------------------------------------
            dispatch('context', newContext)

        } catch (err) {
            console.log('ERROR IN GET DATA CART STORE func(getDataCart)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR IN GET DATA CART STORE func(getDataCart)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }

    })

    store.on('updateInProductCard', async ({ context, closeModalState, cartStore, numberCurrentOrderForAddProduct }, obj, { dispatch }) => {

        try {
            const { role } = context.init_state.profile;
            let tempElement = true;
            let amountTrueItem = 0;
            let allCount = 0;
            let valueEnableAllSelectFromServer = false;
            let params = [];
            if (debounceRequest(obj)) return;

            if (obj.length) {
                for (let i = 0; i < obj.length; i++) {
                    obj[i] = { ...obj[i], add_order_id: numberCurrentOrderForAddProduct, add_product: !!numberCurrentOrderForAddProduct }
                }
            }
            !!obj.length && role === ROLE.WHOLESALE ? params = obj : params = [...obj];

            const res = await apiCart.updateCartData(params);

            if (!res.can_add?.status && res.can_add?.status !== undefined) {
                dispatch('setDataCartStore', {
                    ...cartStore,
                    ...res,
                    isLoadingCart: true
                })
                const text = `${res.can_add?.message ? res.can_add?.message : 'Было привышено одно из условий выкупа'}`;
                return dispatch('setModalState', {
                    show: true,
                    content: textSuccessMessage(text),
                    iconImage: successAlertIcon,
                    action: {
                        title: ['продолжить', null]
                    },
                    onClick: () => closeModalState()
                })
            }

            if (res.cartitem_set[0]?.items) {

                await res.cartitem_set.reduce((prev, cur, index, arr) => {
                    cur.items.filter(el => {
                        allCount += cur.items.length;
                        if (tempElement === el.selected) amountTrueItem++
                        if (allCount === amountTrueItem) return valueEnableAllSelectFromServer = true;
                        return valueEnableAllSelectFromServer = false
                    })
                }, 0)
            } else {
                await res.cartitem_set.reduce((prev, cur, index, arr) => {
                    const allCount = arr.length;
                    if (tempElement === cur.selected) amountTrueItem++
                    if (allCount === amountTrueItem) return valueEnableAllSelectFromServer = true;
                    return valueEnableAllSelectFromServer = false
                }, 0)
            }
            dispatch('setDataCartStore', {
                ...cartStore,
                ...res,
                cart_ids: res.cart_ids,
                cartitem_set: res.cartitem_set,
                in_stock: res.in_stock,
                enableAllSelect: valueEnableAllSelectFromServer,
                agreeWitheRegulations: true,
                isLoadingCart: true
            })
        } catch (err) {
            console.log('ERROR INCREMENT PRODUCT LIST CART func(updateInProductCard)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR INCREMENT PRODUCT LIST CART func(updateInProductCard)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })

    store.on('deleteItemFromCart', async ({ context, closeModalState, cartStore }, obj, { dispatch }) => {
        try {
            const dataCart = cartStore;
            let newDataCart = { ...dataCart };
            if (dataCart?.cartitem_set?.length && dataCart?.cartitem_set[0]?.items?.length) {
                newDataCart = {
                    ...newDataCart,
                    cartitem_set: dataCart.cartitem_set.reduce((prev, cur) => {
                        const items = cur.items.filter(el => el.id !== obj.id);
                        console.log('prev = ', prev)
                        if (!items.length) return prev;
                        prev = [
                            ...prev,
                            {
                                ...cur,
                                items: items
                            }
                        ]
                        return prev;
                    }, []),
                }
            } else {
                newDataCart = {
                    ...newDataCart,
                    cartitem_set: dataCart?.cartitem_set?.filter(el => el.id !== obj.id) ?? []
                }
            }
            if (dataCart.in_stock.length) {
                newDataCart = {
                    ...newDataCart,
                    in_stock: dataCart.in_stock.filter(el => el.id !== obj.id) ?? []
                }
            }

            const res = await apiCart.deleteCartData({ item_id: obj.id });
            dispatch('setDataCartStore', {
                ...newDataCart,
                enableAllSelect: false,
                agreeWitheRegulations: true,
                isLoadingCart: true
            })
            dispatch('getDataCart')

            if (!!!(context.init_state.profile.cart - 1)) {
                dispatch('updateDataRecommendedStore');
            };
        } catch (err) {
            console.log('ERROR DELETE PRODUCT FROM LIST CART func(deleteItemFromCart)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR DELETE PRODUCT FROM LIST CART func(deleteItemFromCart)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }

    })

    store.on('selectAllItemsInCart', async ({ context, cartStore }, obj, { dispatch }) => {
        try {
            // const { dataCart } = context.init_state;
            const dataCart = cartStore;
            const { cartitem_set, enableAllSelect, in_stock } = dataCart;
            let newCartItemSet = []
            let arrayForServerAllSelectItems = [];
            let newInStock = [];

            if (!!!cartitem_set[0]?.items) {
                newCartItemSet = cartitem_set.map(el => ({ ...el, selected: !enableAllSelect }))
                arrayForServerAllSelectItems = newCartItemSet.map(el => ({
                    id: +el.id,
                    qty: +el.qty,
                    selected: el.selected
                }));
            } else {
                newCartItemSet = cartitem_set.map(el => ({
                    ...el,
                    selected: !enableAllSelect,
                    items: el.items.map(item => ({ ...item, selected: !enableAllSelect }))
                }
                ))
                newCartItemSet.filter(el => {
                    const newEl = el.items.map(item => ({
                        id: +item.id,
                        qty: +item.qty,
                        selected: item.selected
                    }))
                    arrayForServerAllSelectItems = [...arrayForServerAllSelectItems, ...newEl]
                }
                )
            }
            if (in_stock.length) {
                newInStock = in_stock.map(el => ({ ...el, selected: !enableAllSelect }));
                let paramsSelect = newInStock.map(el => ({
                    id: el.id,
                    qty: el.qty,
                    selected: el.selected
                }))
                arrayForServerAllSelectItems = [...arrayForServerAllSelectItems, ...paramsSelect]
            }
            //---------------------------------------------------------------
            dispatch('setDataCartStore', {
                ...cartStore,
                cartitem_set: newCartItemSet,
                // enableAllSelect: !enableAllSelect,
                in_stock: newInStock,
                agreeWitheRegulations: true,
                isLoadingCart: true,
            })
            //---------------------------------------------------------------
            await dispatch('updateInProductCard', arrayForServerAllSelectItems)
            // await delay(2000)

        } catch (err) {
            console.log('ERROR selectAllItemsInCart func(selectAllItemsInCart)', err);
            let error = [Text({ text: 'error-on-server' }), 'ERROR selectAllItemsInCart func(selectAllItemsInCart)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})
        }
    })

    store.on('multipleDeleteFromCart', async ({ context, closeModalState, cartStore }, obj, { dispatch }) => {
        try {
            // const { dataCart } = context.init_state;
            const dataCart = cartStore;
            const { cartitem_set, enableAllSelect, in_stock } = dataCart;
            let params = []
            params = listEnableElementInCart(dataCart).map(el => el.selected ? ({ id: el.id, is_pack: el.is_pack, qty: el.qty }) : null).filter(el => el !== null);
            if (!params.length) {//?! нужен попап что не выбран не один элемент

                let text = ['У Вас нет выбранных товаров, для удаления'];

                dispatch('setModalState', {
                    show: true,
                    content: textErrorMessage(text),
                    iconImage: errorAlertIcon,
                    addClass: 'modal-alert-error',
                    action: {
                        title: ['продолжить', null]
                    },
                    onClick: () => closeModalState()
                })
                dispatch('fixErrorBot', { deleteInCart: 'no products delete' })

                return
            }

            const res = await apiCart.multipleDeleteFromCart({ items: params });
            let newDataCart = { ...dataCart };
            if (dataCart?.cartitem_set?.length && dataCart?.cartitem_set[0]?.items?.length) {
                newDataCart = {
                    ...newDataCart,
                    cartitem_set: dataCart.cartitem_set.reduce((prev, cur) => {
                        const items = cur.items.filter(el => !el.selected);
                        if (!items.length) return;
                        let result = [
                            ...prev,
                            {
                                ...cur,
                                items: items
                            }
                        ]
                        return result;
                    }, []) ?? [],
                }
            } else {
                newDataCart = {
                    ...newDataCart,
                    cartitem_set: dataCart.cartitem_set.filter(el => !el.selected) ?? []
                }
            }
            if (dataCart.in_stock.length) {
                newDataCart = {
                    ...newDataCart,
                    in_stock: dataCart.in_stock.filter(el => !el.selected) ?? []
                }
            }

            if (res >= 200 < 300) {
                //---------------------------------------------------------------
                dispatch('setDataCartStore', {
                    ...newDataCart,
                    enableAllSelect: false,
                    agreeWitheRegulations: true,
                    isLoadingCart: true
                })
                //---------------------------------------------------------------
                dispatch('getDataCart')
            }


            if (res === 400) {
                // ?! необходимо сделать попап для предуприждения что не удалось удалить
                dispatch('fixErrorBot', { deleteInCart: 'no delete error 400' });
            }

        } catch (err) {
            console.log('ERROR IN MULTY DELETE PRODUCTS func(multipleDeleteFromCart)', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR IN MULTY DELETE PRODUCTS func(multipleDeleteFromCart)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }

    })

    store.on('handleAgreeWitheRegulations', ({ context, cartStore }, obj, { dispatch }) => {
        try {
            // const { dataCart } = context.init_state;
            const dataCart = cartStore;

            const { agreeWitheRegulations } = dataCart;

            //---------------------------------------------------------------
            dispatch('setDataCartStore', {
                ...cartStore,
                agreeWitheRegulations: !agreeWitheRegulations,
                isLoadingCart: true
            })
            //---------------------------------------------------------------

        } catch (err) {
            console.log('ERROR handleAgreeWitheRegulations func(handleAgreeWitheRegulations)', err)
            dispatch('fixErrorBot', { error: 'ERROR handleAgreeWitheRegulations func(handleAgreeWitheRegulations)', err })
        }
    })

    store.on('addToCart', async ({ context, numberProductPastInCart, closeModalState, productDetails }, obj, { dispatch }) => {
        const { profile } = context.init_state;
        const { role } = profile;
        const { colors, sizes } = productDetails;
        try {
            if (role !== ROLE.UNREGISTRED) {
                let params = {
                    product: obj.productId,
                    color: getActiveColor(colors),
                    size: getActiveSize(sizes),
                    qty: productDetails?.in_cart_count + obj.count ?? 1,
                };
                if (productDetails.is_collection) params = { ...params, is_collection: productDetails.is_collection };
                if (role === ROLE.WHOLESALE) params = Object.assign({}, params, { add_product: true });
                const res = await apiCart.addToCart(params);
                dispatch('saveNumberProductAddToCart', { productId: obj.productId })
                const newContext = {
                    ...context,
                    "init_state": {
                        ...context.init_state,
                        profile: {
                            ...context.init_state.profile,
                            cart: context.init_state.profile.cart + (obj.count ?? 1)
                        }
                    },
                }

                dispatch('context', newContext);

                if (obj.modalView) {
                    // dispatch('quickViewProduct', {
                    //     id: params.product,
                    //     color: params.color,
                    //     size: params.size
                    // })
                } else {
                    dispatch('getProductDetails', {
                        productId: obj.productId,
                        color: colors,
                        size: sizes
                    })
                }

                if (numberProductPastInCart !== obj.productId && !obj?.modalView) {
                    const timerSetTimeout = setTimeout(() => {
                        dispatch('modalRedirectToCart', {
                            redirectTo: obj.redirectTo
                        })
                        return clearTimeout(timerSetTimeout)
                    }, 100)
                }

            } else {
                let textMessage = ['Чтобы воспользоваться всеми возможностями сотрудничества, необходимо зарегистрироваться'];
                const params = {
                    show: true,
                    content: textErrorMessage(textMessage),
                    iconImage: errorAlertIcon,
                    addClass: 'modal-alert-error',
                    onClick: () => closeModalState(),
                    action: {
                        title: ['остаться', 'Пройти регистрацию']
                    },
                    onClickCancel: () => {
                        obj.redirectTo('/registration');
                        closeModalState();
                    }
                };
                dispatch('setModalState', params)

            }
        } catch (err) {
            console.log('ERROR ADD TO CART func(addToCart) ', err)
            let error = [Text({ text: 'error-on-server' }), 'ERROR ADD TO CART func(addToCart) '];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            dispatch('fixErrorBot', {
                product_id: obj.productId,
                is_collection: productDetails?.is_collection,
                color: getActiveColor(colors),
                size: getActiveSize(sizes),
                qty: productDetails?.in_cart_count + obj?.count,
                ...error,
                err
            })
            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }


    })
    //------------------------------------------------------------------------------------------------------------------
    const checkEnableAllElementInCart = (data) => {
        let newDataCartSet = []
        if (data?.cartitem_set.length && data.cartitem_set[0]?.items) {
            data?.cartitem_set.map(item => newDataCartSet = [...newDataCartSet, ...item.items]);
        } else {
            data?.cartitem_set.map(item => newDataCartSet = [...newDataCartSet, ...[item]]);
        }
        if (data?.in_stock.length) {
            data?.in_stock.map(item => newDataCartSet = [...newDataCartSet, ...[item]]);
        }
        return !(newDataCartSet.map(el => el.selected).includes(false))
    }
    const listEnableElementInCart = (data) => {
        let newDataCartSet = []
        if (data?.cartitem_set.length && data.cartitem_set[0]?.items) {
            data?.cartitem_set.map(item => newDataCartSet = [...newDataCartSet, ...item.items]);
        } else {
            data?.cartitem_set.map(item => newDataCartSet = [...newDataCartSet, ...[item]]);
        }
        if (data?.in_stock.length) {
            data?.in_stock.map(item => newDataCartSet = [...newDataCartSet, ...[item]]);
        }
        return newDataCartSet;
    }
    const initValueCart = {
        cartitem_set: [],
        in_stock: [],
        enableAllSelect: false,
        agreeWitheRegulations: true,
        valueButtonNextToOrder: 'Загрузка ...',
        isLoadingCart: false,
    }
    store.on('@init', () => ({ cartStore: { ...initValueCart } }));
    store.on('setDataCartStoreZero', ({ }, obj) => ({cartStore: initValueCart}));
    store.on('setDataCartStore', ({ }, obj) => ({
        cartStore: {
            ...obj,
            enableAllSelect: checkEnableAllElementInCart(obj)
        }
    }))
}

