import api from "../../api/api";
import BlockGrid from '../../Views/GridContainerBlock';
import AsyncComponent from "../../helpers/asyncComponent";
import { textErrorMessage } from "../modalStorage/modalWindow/modalWindow";
import { defaultProductCard, defaultProductThumb, errorAlertIcon } from "../../images";
import { getActiveColor, getActiveSize } from "../../helpers/helpers";
const AsyncProductCardModal = AsyncComponent(() => {
    return import('../../Views/PreviewProduct/PreviewProductCardModal/PreviewProductCardModalContainer');
})
const AsyncPreviewSlider = AsyncComponent(() => {
    return import('../../Views/PreviewProduct/PreviewSlider/PreviewSliderContainer');
});

export const quickViewProduct = store => {
    const apiContent = api.contentApi;

    store.on('previewProductPhoto', async ({ context, closeModalState, currency }, obj, { dispatch }) => {

        try {
            const { role, status } = context.init_state.profile;

            let params = {
                color: getActiveColor(obj.color),
                size: getActiveSize(obj.size),
            }

            const res = await apiContent.getProduct(obj.id, params)

            dispatch('setDataProductDetailsStore', { ...res })

            dispatch('setModalState', {
                title: res.title,
                content: (
                    <BlockGrid.BlockCenter>
                        {/* <AsyncProductCardModal
                            url={obj.url}
                            brand={res.brand}
                            role={role}
                            productId={res.id}
                            profileId={0}
                            adding_type={'item'}
                            breadcrumbs={[]}
                            reviews_statistic={{}}
                            reviewsCount={res.review.all_count}
                            title={res.title}
                            prices={res.prices}
                            recommended_price={0}
                            colors={res.colors}
                            sizes={res.sizes}
                            review={res.review}
                            is_new={res.is_new}
                            in_stock_count={res.in_stock_count}
                            is_bestseller={res.is_bestseller}
                            is_in_stock={res.is_in_stock}
                            role_configuration={{}}
                            is_closeout={res.is_closeout}
                            is_liked={res.is_liked}
                            media={res.media}
                            in_cart_count={res.in_cart_count}
                            site_configuration={{}}
                            is_collection={res.is_collection}
                            product_rc={res.product_rc}
                            article={res.article}
                            product_rcAmount={res.minimum_rc}
                            product_sku={res.product_sku}
                            currency={currency}
                            collections={res.collections}
                            status={status}

                        /> */}
                    <div
                        style ={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            transform: `rotate(-45deg) translate(1px, 56px)`,
                            zIndex: 9999999,
                            color: `#f1969e`,
                            fontSize: `20px`,
                            ":hover": {
                                cursor: 'pointer'
                              },
                        }}
                        className="link"
                        onClick={()=>{
                            obj.redirectTo(`/${obj.url}`)
                            closeModalState()
                        }
                        }
                    >Перейти в товар</div>
                    <AsyncPreviewSlider
                        defaultImage={defaultProductCard}
                        defaultThumb={defaultProductThumb}
                        imageOrVideoSet={res.media}
                        isPreview
                        zoom
                    />
          
                    </BlockGrid.BlockCenter>

                ),
                show: true,
                addClass: 'modal-quick-view-product',
            })


        } catch (err) {
            console.log('Error in quick view product f(quickViewProduct)', err)
            let error = [Text({ text: 'error-on-server' }), 'Error in quick view product f(quickViewProduct)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })
    store.on('quickViewProduct', async ({ context, closeModalState, currency }, obj, { dispatch }) => {

        try {
            const { role, status } = context.init_state.profile;

            let params = {
                color: getActiveColor(obj.color),
                size: getActiveSize(obj.size),
            }

            const res = await apiContent.getProduct(obj.id, params)

            dispatch('setDataProductDetailsStore', { ...res })

            dispatch('setModalState', {
                title: res.title,
                content: (
                    <BlockGrid.BlockCenter>
                        <AsyncProductCardModal
                            url={obj.url}
                            brand={res.brand}
                            role={role}
                            productId={res.id}
                            profileId={0}
                            adding_type={'item'}
                            breadcrumbs={[]}
                            reviews_statistic={{}}
                            reviewsCount={res.review.all_count}
                            title={res.title}
                            prices={res.prices}
                            recommended_price={0}
                            colors={res.colors}
                            sizes={res.sizes}
                            review={res.review}
                            is_new={res.is_new}
                            in_stock_count={res.in_stock_count}
                            is_bestseller={res.is_bestseller}
                            is_in_stock={res.is_in_stock}
                            role_configuration={{}}
                            is_closeout={res.is_closeout}
                            is_liked={res.is_liked}
                            media={res.media}
                            in_cart_count={res.in_cart_count}
                            site_configuration={{}}
                            is_collection={res.is_collection}
                            product_rc={res.product_rc}
                            article={res.article}
                            product_rcAmount={res.minimum_rc}
                            product_sku={res.product_sku}
                            currency={currency}
                            collections={res.collections}
                            status={status}

                        />
                    </BlockGrid.BlockCenter>

                ),
                show: true,
                addClass: 'modal-quick-view-product',
            })


        } catch (err) {
            console.log('Error in quick view product f(quickViewProduct)', err)
            let error = [Text({ text: 'error-on-server' }), 'Error in quick view product f(quickViewProduct)'];
            if (err?.data) {
                const errors = err.data;
                if (typeof errors !== 'object') {
                    error.push(`${errors}`)
                } else {
                    error.push(`${errors[0]}`)
                }
            }
            // dispatch('fixErrorBot',{...error, err})

            dispatch('setModalState', {
                show: true,
                content: textErrorMessage(error),
                iconImage: errorAlertIcon,
                addClass: 'modal-alert-error',
                action: {
                    title: ['продолжить', null]
                },
                onClick: () => closeModalState()
            })
        }
    })
}