import { getToken } from "firebase/messaging";
import api from "./api/api";
import { UNREG_PUSH_v } from "./const";
import { getLocalStorage, setLocalStorage } from "./helpers/helpers";
import { messaging } from "./firebase";

export async function register() {
    if ('serviceWorker' in navigator) {
        // const swUrl = './sw.js';
        const swUrlForCloudeMessage = "./firebase-messaging-sw.js";
          registerValidSW(swUrlForCloudeMessage);
    }
  } 
  
  async function registerValidSW(swUrl) {
    const VAPID_PUBLIC_KEY =
    //   "BIqYIeK2W_V6W4O-i6iW0D3DLat9obm70B-PeFSjWEVwr1PeVD45N0mLPtRnhBC1Tn2AZu-fBJi-aJpwf2OCtOM"; // old
      "BHzMeYARZ4RjGdYsXN69S4v2izmU35ifbzd4vJIlE7zpDiTV7zjg3UCuuIs9FOGdgqQUWfLm_R_AUxT3jkhTcgw"; // new

    try {
      // const checkRegistration = await window.navigator.serviceWorker.ready;
      if (window.location.href.includes("/orders/")) return;
      const registration = await navigator.serviceWorker.register(swUrl);
      const permissionNotice = await Notification.requestPermission();
      if (permissionNotice === "granted") {
        getToken(messaging, { vapidKey: VAPID_PUBLIC_KEY })
          .then(async (currentToken) => {
            if (currentToken) {
              console.log("FCM Токен:", currentToken);
                const getBrowser = loadVersionBrowser();
                const params = {
                  browser: getBrowser.name.toUpperCase(),
                  endpoint: currentToken,
                  // p256dh: currentToken,
                  auth: currentToken,
                  device_id: currentToken,
                };
              const createHandShakeFromBeckend =
                await api.profileApi.postNotificationsServiceWorker(params);
              const { result } = createHandShakeFromBeckend;
              console.log(
                "Рукопожатие прошло успешно админ знает про нас ",
                result
              );
            } else {
              console.log("Не удалось получить токен");
            }
          })
          .catch((err) => console.log("Ошибка получения токена", err));
        if (navigator.serviceWorker.controller) {
          // try {
          //   const getSw = await navigator.serviceWorker.ready;
          //   const subscription = await getSw.pushManager.getSubscription();
          //   if (!!subscription) return;
          // } catch (err) {
          //   console.log(err);
          // }
        }
        //   const subscr = await registration.pushManager.subscribe({
        //     userVisibleOnly: true,
        //     applicationServerKey: urlBase64ToUint8Array(VAPID_PUBLIC_KEY),
        //   });
        //   const getBrowser = loadVersionBrowser();
        //   const params = {
        //     browser: getBrowser.name.toUpperCase(),
        //     endpoint: subscr.endpoint,
        //     p256dh: btoa(
        //       String.fromCharCode.apply(
        //         null,
        //         new Uint8Array(subscr.getKey("p256dh"))
        //       )
        //     ),
        //     auth: btoa(
        //       String.fromCharCode.apply(
        //         null,
        //         new Uint8Array(subscr.getKey("auth"))
        //       )
        //     ),
        //   };
        //   const createHandShakeFromBeckend =
        //     await api.profileApi.postNotificationsServiceWorker(params);
        //   const { result } = createHandShakeFromBeckend;
        //   console.log("Рукопожатие прошло успешно админ знает про нас ", result);
      }
    } catch (e) {
      console.log(e);
      console.log(
        "Ошибка в регистрации service worker" + e.name + ":" + e.message
      );
    }
  }
  
 const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');
    const rawData = window.atob(base64);
    let outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
  
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.pushManager.getSubscription()
                      .then( subscription => {
                          if (!!subscription){
                            subscription.unsubscribe()
                            .then(function(successful) {
                              console.log('You have successfully unsubscribed');
                            })
                            .catch(err=>console.error('не удалось отписаться от бэка'))
                          }
          })
        registration.unregister()
            .then(function(successful) {
              setLocalStorage(UNREG_PUSH_v, 'true')             
              console.log('You have successfully unregister SW');
            })
            .catch(err=>console.error('не удалось отписаться от бэка'))
      });
    }
  }


  function loadVersionBrowser() {
    if ("userAgentData" in navigator) {
      // navigator.userAgentData is not available in
      // Firefox and Safari
      const uaData = navigator.userAgentData || navigator.userAgent;
      // Outputs of navigator.userAgentData.brands[n].brand are e.g.
      // Chrome: 'Google Chrome'
      // Edge: 'Microsoft Edge'
      // Opera: 'Opera'
      let browsername;
      let browserversion;
      let chromeVersion = null;
      for (var i = 0; i < uaData.brands.length; i++) {
        let brand = uaData.brands[i].brand;
        browserversion = uaData.brands[i].version;
        if (brand.match(/opera|chrome|edge|safari|firefox|msie|trident/i) !== null) {
          // If we have a chrome match, save the match, but try to find another match
          // E.g. Edge can also produce a false Chrome match.
          if (brand.match(/chrome/i) !== null) {
            chromeVersion = browserversion;
          }
          // If this is not a chrome match return immediately
          else {
            browsername = brand.substr(brand.indexOf(' ') + 1);
            return {
              name: browsername,
              version: browserversion
            }
          }
        }
      }
      // No non-Chrome match was found. If we have a chrome match, return it.
      if (chromeVersion !== null) {
        return {
          name: "chrome",
          version: chromeVersion
        }
      }
    }
    // If no userAgentData is not present, or if no match via userAgentData was found,
    // try to extract the browser name and version from userAgent
    const userAgent = navigator.userAgent;
  
    let ua = userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1]
    };
  };

// определение что мобильное устройство
  //(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))