import axios from "axios";
import api from "../../api/api";
import { arrayClone, getActiveColor, getActiveSize, shuffle } from "../../helpers/helpers";

export const ProductDetails = (store) => {
  const apiContent = api.contentApi;
  
  store.on('@init',()=>({tempProductId: undefined}))
  store.on('setTempProductId',({_}, obj)=>{
    return ({tempProductId: obj})
  })
  store.on('@init',()=>({tempSizeProduct: undefined}))
  store.on('setTempSizeProductId',({_}, obj)=>({tempSizeProduct: obj}))

  store.on("getProductDetails",async ({ tempProductId, tempSizeProduct, productDetails }, obj, { dispatch }) => {
      try {
        // console.time("start get data from function getProductDetails");
        let newMedia = [];
        const { productId } = obj;
        if (productId === undefined) return;    
        dispatch('setTempProductId',productId);

        let params = {};
        if (obj?.color && obj?.size) {
          const checkSize = (getActiveSize(obj.size) === tempSizeProduct)
          dispatch('setTempSizeProductId', getActiveSize(obj?.size))
          params = {
            id: productId,
            color: getActiveColor(obj.color),
            size: getActiveSize(obj.size),
            collection: null,
          // pack ??????
          };
          const copyStateMedia = shuffle(arrayClone(productDetails.media.filter( el => !el.hasOwnProperty('color'))));
          const copyStateMediaProductSku = arrayClone(productDetails.product_sku.filter( el => el.color === params.color)).slice(0,1);
          console.log({copyStateMediaProductSku})
          // let newCopyStateMediaProdct = []
          // newCopyStateMediaProdct.push({
          //   image: copyStateMediaProductSku.image,
          //   image_thumb: copyStateMediaProductSku.image_thumb,
          //   type: "image",
          // })
          debugger
          const newProductDetails = {
            colors: [
              ...productDetails.colors.map((item) =>
                item.id === params.color
                  ? { ...item, selected: true }
                  : { ...item, selected: false }
              ),
            ],
            sizes: [
              ...productDetails.sizes.map((item) =>
                item.id === params.size
                  ? { ...item, selected: true }
                  : { ...item, selected: false }
              ),
            ],
            media: [
              // ...copyStateMediaProductSku,//productDetails.product_sku.filter( el => el.color === params.color),
              {
                image: copyStateMediaProductSku[0].image,
                image_thumb: copyStateMediaProductSku[0].image_thumb,
                type: "image",
              },
              ...copyStateMedia
            ],
            isLoadingProductData: tempSizeProduct && checkSize ? false : true,
          };
          console.log({newProductDetails})
          dispatch("setDataProductDetailsStore", newProductDetails);
          
          if(obj?.source){
            params = {
              ...params,
              source: obj.source,
            };
          }
          let paramsDataProduct = {          
            ...newProductDetails,          
            isLoadingProductData: false,
          };    
          if(!checkSize){
            const res = await apiContent.getProduct(productId, params);
              res.product_sku.filter((el) => el.color === (params?.color ?? getActiveColor(res.colors))? 
                newMedia.push({
                  image: el.image,
                  image_thumb: el.image_thumb,
                  type: "image",
                }) : []);
            paramsDataProduct = {
              ...res,
              in_stock_count: res.in_stock_count,
              in_cart_count: res.in_cart_count,
              is_bestseller: res.is_bestseller,
              is_collection: res.is_collection,
              collections: res.collections,
              is_closeout: res.is_closeout,
              is_in_stock: res.is_in_stock,
              product_rc: res.product_rc,
              minimum_rc: res.minimum_rc,
              is_new: res.is_new,
              prices: { ...res.prices },
              colors: [...res.colors],
              sizes: [...res.sizes],
              media: [newMedia[0], ...res.media],
              brand: res.brand,
              title: res.title,
              id: res.id,
              isLoadingProductData: false,
            };
            dispatch("setDataProductDetailsStore", paramsDataProduct);
          }
        } else {
          const newProductDetails = {
            ...newProductDetails,     
            isLoadingProductData: true,
          };
          dispatch("setDataProductDetailsStore", newProductDetails);
          const res = await apiContent.getProduct(productId, params);

          // console.timeEnd("получение деталей продукта");

          if (!res.product_sku.length) alert(" is not now product_sku");
          res.product_sku.filter((el) =>
          el.color === (params?.color ?? getActiveColor(res.colors))
          ? newMedia.push({
            image: el.image,
            image_thumb: el.image_thumb,
            type: "image",
          })
          : []
          );
          
          dispatch('setTempSizeProductId', getActiveSize(res.sizes))

          const paramsDataProduct = {
            ...res,
            in_stock_count: res.in_stock_count,
            in_cart_count: res.in_cart_count,
            is_bestseller: res.is_bestseller,
            is_collection: res.is_collection,
            collections: res.collections,
            is_closeout: res.is_closeout,
            is_in_stock: res.is_in_stock,
            product_rc: res.product_rc,
            minimum_rc: res.minimum_rc,
            is_new: res.is_new,
            prices: { ...res.prices },
            colors: [...res.colors],
            sizes: [...res.sizes],
            media: [newMedia[0], ...res.media],
            brand: res.brand,
            title: res.title,
            id: res.id,
            isLoadingProductData: false,
          };
          dispatch("setDataProductDetailsStore", paramsDataProduct);         
        }
      } catch (err) {
        const newProductDetails = {
          ...productDetails,
          isLoadingProductData: false,
        };
        dispatch("setDataProductDetailsStore", newProductDetails);

        console.log(
          "Sorry something went wrong get product detail :) f(getProductDetails) ",
          err
        );
        if (err?.statusText === "Not Found") {
          dispatch("setModalState", {
            show: true,
            content: <div>Товар отсутствует в нашем каталоге</div>,
          });
        } else {
          dispatch("fixErrorBot", { error:"Sorry something went wrong get product detail :) f(getProductDetails) ",err,});
        }
      }
    }
  );

  const initValueProductDetails = {
    id: null,
    title: "",
    category: "",
    brand: "",
    slug: "",
    minimum_rc: null,
    minimum_rc_price: null,
    media: [],
    colors: [],
    sizes: [],
    collections: [],
    is_new: false,
    is_bestseller: false,
    is_closeout: false,
    is_in_stock: false,
    is_liked: false,
    in_cart_count: 0,
    in_stock_count: 0,
    prices: {
      price: null,
      old_price: null,
      more_3_item_price: null,
      more_5_item_price: null,
    },
    product_rc: "",
    is_collection: false,
    product_sku: [],
    content: "",
    extra: "",
    short_content: "",
    created_at: "",
    updated_at: "",
    ordering: 0,
    review: {
      all_count: 0,
      all_count_percent: 0,
    },
    seo_title: "",
    seo_keywords: "",
    seo_description: "",
    seo_author: "",
    seo_og_type: "website",
    seo_image: null,
    article: "",
    product_url: "",
    isLoadingProductData: false,
  };
  store.on("@init", () => ({ productDetails: { ...initValueProductDetails } }));
  store.on('getProductDetailsZerro', () => ({ productDetails: { ...initValueProductDetails } }));
  store.on("setDataProductDetailsStore", ({ productDetails }, obj) => {
    return ({
    productDetails: {
      ...productDetails,
      ...obj,
      media: obj?.media ?? productDetails.media,
      colors: obj?.colors ?? productDetails.colors,
      sizes: obj?.sizes ?? productDetails.sizes,
      collections: obj?.collections ?? productDetails.collections,
      product_sku: obj?.product_sku ?? productDetails.product_sku,
    },
  })});
};
